<template>
  <form action="#" method="POST">
    <div class="overflow-hidden">
      <div class="px-2">
        <div class="grid grid-cols-6 gap-6">
          <div class="col-span-6">
            <label for="name" class="block text-sm font-medium text-gray-700"
              >Nom</label
            >
            <input
              type="text"
              name="name"
              id="name"
              autocomplete="name"
              v-model="data.title"
              class="
                mt-1
                focus:ring-primary focus:border-primary
                block
                w-full
                shadow-sm
                sm:text-sm
                border-gray-300
                rounded-md
              "
            />
          </div>
          <div class="col-span-6">
            <label
              for="institution"
              class="block text-sm font-medium text-gray-700"
              >Institution</label
            >
            <div class="mt-1 sm:mt-0 sm:col-span-2">
              <select v-model="data.institutionName" id="institution" name="institution" class="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-primary sm:text-sm sm:leading-6">
                <option value="Ministère de l'économie et des finances -MEF-">Ministère de l'économie et des finances -MEF-</option>
                <option value="Ministère de la transition numérique et de la réforme de l'administration">Ministère de la transition numérique et de la réforme de l'administration</option>
                <option value="Parlement: Chambre des conseillers/ Chambre des représentants">Parlement: Chambre des conseillers/ Chambre des représentants</option>
                <option value="Cour des comptes ">Cour des comptes</option>
                <option value="Haut-commissariat au plan -HCP- ">Haut-commissariat au plan -HCP-</option>
                <option value="Inspection générale des finances -IGF-MEF-">Inspection générale des finances -IGF-MEF-</option>
                <option value="Conseil économique, social et environnemental -CESE-">Conseil économique, social et environnemental -CESE-</option>
                <option value="Conseil de la concurrence">Conseil de la concurrence</option>
                <option value="Gouvernement Ouvert Maroc">Gouvernement Ouvert Maroc</option>
                <option value="Transparency International">Transparency International</option>
              </select>
            </div>
          </div>
          <div class="col-span-6">
            <label for="link" class="block text-sm font-medium text-gray-700"
              >Lien</label
            >
            <input
              type="text"
              name="link"
              id="link"
              autocomplete="link"
              v-model="data.link"
              class="
                mt-1
                focus:ring-primary focus:border-primary
                block
                w-full
                shadow-sm
                sm:text-sm
                border-gray-300
                rounded-md
              "
            />
          </div>
        </div>
      </div>
      <p class="text-sm text-red-500 mt-4" v-if="errMessage"> {{ errMessage }}</p>
      <div class="py-4 text-right px-2">
        <button
          @click.prevent="handleSubmit"
          type="submit"
          class="
            inline-flex
            justify-center
            py-2
            px-4
            border border-transparent
            shadow-sm
            text-sm
            font-medium
            rounded-md
            text-white
            bg-primary
            hover:bg-primary
            focus:outline-none
            focus:ring-2
            focus:ring-offset-2
            focus:ring-primary
          "
        >
          Soumettre
        </button>
      </div>
    </div>
  </form>
</template>

<script setup>
import { ref, toRefs } from "vue";
import { useStore } from "vuex";
const { dispatch } = useStore();
const props = defineProps({
  data: {
    type: Object,
    default: {
      title: "",
      InstitutionName: "",
      link:""
    },
  },
});
const emit = defineEmits(["modalClose"]);
const errMessage = ref('');

// Document logic
const {data} = toRefs(props);
console.log(data.value);
const handleSubmit = async () => {
  // Update
  if (data.value.id) {
    try {
      // Update document
      await dispatch("documents/update", data.value);
      emit("modalClose");
    } catch (err) {
      const {message} = err.response.data;
      if(message) errMessage.value = message;
    }
  }
  // Create
  else {
    try {
      // Create document
      await dispatch("documents/create", data.value);
      emit("modalClose");
    } catch (err) {
      const {message} = err.response.data;
      if(message) errMessage.value = message;

    }
  }
};
</script>

<style lang="scss" scoped>
</style>