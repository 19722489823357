<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <div class="flex flex-col">
    <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
      <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
        <div
          class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg"
        >
          <table class="documents-table">
            <thead class="documents-table__header">
              <tr>
                <th scope="col" class="documents-table__header-col">Nom</th>
                <th scope="col" class="documents-table__header-col">Institution</th>
                <th scope="col" class="relative px-6 py-3">
                  <span class="sr-only">Modifier</span>
                </th>
              </tr>
            </thead>
            <tbody class="documents-table__body">
              <tr
                class="documents-table__row"
                v-for="document in documents"
                :key="document.id"
              >
                <td class="documents-table__row-cel">
                  <div class="flex items-center">
                    <div class="ml-4">
                      <a :href="document.link" target="_blank" class="text-sm font-medium text-gray-900 capitalize">
                        {{ document.title }}
                      </a>
                    </div>
                  </div>
                </td>
                <td class="documents-table__row-cel documents-table__row-cel--fixed">
                  <div class="text-sm text-gray-900">
                    <span>{{document.institutionName}}</span>
                  </div>
                </td>
                <td class="documents-table__row-cel text-right text-sm font-medium">
                  <a @click.prevent="$emit('updateDocument',document)" href="#" class="text-primary hover:text-primary/90 mr-2 cursor-pointer"
                    >Modifier</a
                  >
                  <a @click.prevent="deleteDocument(document.id)" class="text-red-500 hover:text-red-400 cursor-pointer"
                    >Supprimer</a
                  >
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <molecule-pagination 
      v-if="pageInfos" 
      @pageChange="getPageData($event)" 
      :pageInfos="pageInfos"
      :currentPage="currentPage"
    ></molecule-pagination>
  </div>
</template>

<script setup>
import { computed, toRefs, ref, inject, onBeforeMount } from "vue";
import MoleculePagination from "@/components/molecules/MoleculePagination.vue";
import BlockConfirmationModal from "@/components/blocks/BlockConfirmationModal.vue";
import {useStore} from "vuex";
const {dispatch, state } = useStore();

const documents = computed( () => state.documents.documents?.data);
const pageInfos = computed( () => state.documents.documents?.meta);
const currentPage = ref(1);
const isConfirmationModalOpen = ref(false);
const confirmDeletion = ref(true);
const confirmationModalContent = ref({
  title:'Supprimer le document',
  description: 'Êtes-vous sûr de vouloir supprimer le document ?',
  primaryCta:'Supprimer'
});
const fetchDocuments = async () => {
  await dispatch('documents/getAll', {
    page:currentPage.value
  });
}
const getPageData = async (page) => {
  currentPage.value = page;
  await fetchDocuments();
}
const deleteDocument = async (documentId) => {
  try {
    const deletedCommunity = await dispatch('documents/delete', documentId);
  } catch (err) {
    const {message} = err.response.data;
    console.log(message);
  }
}
onBeforeMount( async () => {
  await fetchDocuments();
});
</script>
<style lang="scss" scoped>
.documents-table {
  @apply min-w-full divide-y divide-gray-200 table-fixed;
  &__header {
    @apply bg-gray-50;
    &-col {
      @apply px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider;
    }
  }
  &__body {
    @apply bg-white divide-y divide-gray-200;
  }
  &__row {
    &-cel {
      @apply px-6 py-4 whitespace-nowrap;
      &--fixed{
        @apply min-w-[250px] 
        whitespace-normal;
      }
      &-status {
        @apply px-2 inline-flex text-xs leading-5 font-semibold rounded-full;
        &--active {
          @apply bg-green-100 text-green-600;
        }
        &--blocked {
          @apply bg-red-100 text-red-600;
        }
        &--pending {
          @apply bg-gray-100 text-gray-600;
        }
      }
    }
  }
}
</style>
