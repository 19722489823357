<template>
  <div class="mt-5">
    <block-dashboard-wrapper title="Liste des documents">
      <template v-slot:button>
        <button
          type="button"
          class="content-wrapper__header-button"
          @click="createDocument()"
        >
          Ajouter un document
        </button>
      </template>
      <block-documents-table @updateDocument="editDocument($event)"></block-documents-table>
    </block-dashboard-wrapper>
    <molecule-modal-wrapper :open="isModalOpen" @modalClose="closeModal()">
      <block-documents-form
        @modalClose="closeModal()"
        :data="modalDocument"
      ></block-documents-form>
    </molecule-modal-wrapper>
  </div>
</template>

<script setup>
import BlockDashboardWrapper from "@/components/blocks/BlockDashboardWrapper.vue";
import BlockDocumentsTable from "@/components/blocks/tables/BlockDocumentsTable.vue";
import MoleculeModalWrapper from "@/components/molecules/MoleculeModalWrapper.vue";
import BlockDocumentsForm from "@/components/blocks/forms/BlockDocumentsForm.vue";
import { ref } from "vue";

// Modal code
const isModalOpen = ref(false);
const isEditMode = ref(false);
const closeModal = () => {
  isModalOpen.value = false;
};
// Modal document code
const modalDocument = ref({});
// Create document function
const createDocument = () => {
  isModalOpen.value = true;
  modalDocument.value = {
    title: "",
    institutionName: "",
    link: "",
  };
};
// Edit document function
const editDocument = (data) => {
  console.log("editing ..");
  const {createdAt, updateAt, ...documentToUpdate} = data;
  modalDocument.value = documentToUpdate;
  isModalOpen.value = true;
};
</script>

<style lang="scss" scoped></style>
